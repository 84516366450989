import React from "react"
import { Row, Col, Container, Image } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

class ThankYouPage extends React.Component{

    render(){
    
        return(

            <Layout thankPage={{ pageName: "Thank You Page" }}>
            <SEO title="Home" keywords={[`Happy Love Guide`, `Love Coach`, `Jose Learning Center`]} />

            <Container>
                <Row className="justify-content-md-center">
                <Col xs lg="12">
                    <Col className="wrappe-form-hlg">

                        <h1 className="font-slug">TERIMA KASIH</h1>
                        <p className="font-content">Setelah Kamu Mengisi Nama dan Email Aktif Kamu, <br/>Silahkan Cek Email Kamu Untuk Mendapatkan <br/>Link Download Happy Love Guide</p>

                        <p className="text-after-button mt-3">#HappyLoveGuide #JoseLearningCenter</p>
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%20Logo.png" className="size-logo-ebook" /> 
                            </Col>
                        </Row>
                    </Col>
                </Col>
                </Row>
            </Container>

            </Layout>

        )
    }

}

export default ThankYouPage